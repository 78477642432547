<template>
  <div class="content-container" id="teacher-create-container">
    <PageTitle :title="$t('TEACHER_OVERVIEW.TEACHERS')" class="page-title">
      <template #extra-content>
        <ActionButton :icon="require('../../../assets/icons/icn_delete.svg')" class="delete-button" :text="$t('TEACHER_OVERVIEW.EDIT_TEACHER.DELETE_TEACHER')" @click="deleteTeacherButtonPressed" />
      </template>
    </PageTitle>

    <h1 class="page-section-title section-title">
      {{ $t('TEACHER_OVERVIEW.EDIT_TEACHER.EDIT_TEACHER') }}
    </h1>
    <BackButton
      :text="$t('BACK_OVERVIEW')"
      :route-name="ROUTE_NAMES_HELPDESK.TEACHER_DETAILS"
      :params="{ teacherId: teacherId }"
    />

    <FormWrapper :send-form-data="validateData" class="page-form">
      <template #form-content>
        <!-- Name -->
        <InputField
          v-if="teacherDetails !== undefined"
          :field-title="$t('TEACHER_OVERVIEW.CREATE_TEACHER.TEACHER_NAME_FIRST')"
          :field-name="'firstName'"
          :rules="'required'"
          :type="'text'"
          :placeholder="$t('FIRST_NAME_PLACEHOLDER')"
          :value="teacherDetails.firstName"
          :cy-selector="'name-input'"
          :input-icon-error="require('../../../assets/icons/icn_error.svg')"
          :api-error-text="''"
          class="form-element"
        />

        <InputField
          v-if="teacherDetails !== undefined"
          :field-title="$t('TEACHER_OVERVIEW.CREATE_TEACHER.TEACHER_NAME_LAST')"
          :field-name="'lastName'"
          :rules="'required'"
          :type="'text'"
          :placeholder="$t('LAST_NAME_PLACEHOLDER')"
          :value="teacherDetails.lastName"
          :cy-selector="'name-input'"
          :input-icon-error="require('../../../assets/icons/icn_error.svg')"
          :api-error-text="''"
          class="form-element"
        />

        <!-- Email -->
        <InputField
          v-if="teacherDetails !== undefined"
          :field-title="$t('EMAIL_ADDRESS')"
          :field-name="'email'"
          :rules="'required'"
          :type="'email'"
          :placeholder="$t('TEACHER_OVERVIEW.CREATE_TEACHER.EMAIL_PLACEHOLDER')"
          :value="teacherDetails.email"
          :cy-selector="'name-input'"
          :input-icon-error="
            require('../../../assets/icons/icn_error.svg')
          "
          :api-error-text="''"
          class="form-element"
        />

        <!-- School -->
        <Search
          v-if="teacherDetails !== undefined"
          :search-title="$t('SEARCH.INPUT_TITLE')"
          :placeholder="$t('SEARCH.PLACEHOLDER')"
          :value="searchValue"
          :search-id="'search2'"
          :manage-search="manageSearch"
          :clear-search="clearSearch"
          :search-results="searchResults"
          class="search-menu"
        >
          <!-- Result indicator -->
          <template #content-below="searchedTerm">
            <p v-if="searchedTerm && searchResults" class="result-indicator">
              {{ searchResults.length }} {{ $t('RESULTS') }}
            </p>
          </template>
        </Search>

        <!-- Search results -->
        <SearchResults
          :search-results="searchResults"
          :on-item-click="onItemClick"
        />
      </template>

      <template #button-submit>
        <button
          type="submit"
          class="pink-button submit-button"
        >
          {{ $t('TEACHER_OVERVIEW.EDIT_TEACHER.SAVE_CHANGES') }}
        </button>
      </template>
    </FormWrapper>

    <!-- "Save changes?" Warning -->
    <DeleteTeacherWarning v-if="activeBasicModal === MODAL_NAMES.DELETE_TEACHER" ref="deleteWarningModal"/>
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import BackButton from '@/components/elements/BackButton'
import InputField from '@/components/yo-form/InputField'
import FormWrapper from '@/components/yo-form/FormWrapper'
import { ROUTE_NAMES_HELPDESK } from '@/router/modules/helpdesk'
import Search from '@/components/elements/selectSchools/Search'
import SearchResults from '@/components/elements/selectSchools/SearchResults'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { SEARCH_SCHOOLS } from '@/store/modules/auth/actions'
import { RESET_SEARCHED_SCHOOLS } from '@/store/modules/auth/mutations'
import {
  DELETE_TEACHER,
  GET_TEACHER_DETAILS,
  HELPDESK_EDIT_TEACHER
} from '@/store/modules/helpdesk/actions'
import { computed } from '@vue/reactivity'
import { ref, watch } from 'vue'
import { RESET_BASIC_MODAL_DATA, SET_BASIC_MODAL_DATA, SET_TOAST_DATA } from '@/store/mutations'
import { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import DeleteTeacherWarning from '@/components/elements/helpdeskClasses/DeleteTeacherWarning'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import ActionButton from '@/components/table/ActionButton'

export default {
  name: 'TeacherEdit',
  components: {
    PageTitle,
    BackButton,
    InputField,
    FormWrapper,
    Search,
    SearchResults,
    ActionButton,
    DeleteTeacherWarning
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const teacherId = route.params.teacherId
    const store = useStore()
    const searchResults = computed(() => store.getters.getSearchedSchools.items ?? [])

    const activeBasicModal = computed(() => store.getters.getActiveBasicModal)
    function reloadTeacherDetails() {
      store.dispatch(GET_TEACHER_DETAILS, teacherId)
    }



    reloadTeacherDetails()

    const teacherDetails = computed(() => {
      if (store.getters.getTeacherDetails) {
        return store.getters.getTeacherDetails
      }
      return undefined
    })

    watch(() => teacherDetails.value, (curr, prev) => {
      searchValue.value = curr.schoolName
    })




    /** Search **/
    function manageSearch(data) {
      if (!data) {
        clearSearch()
      } else {
        searchValue.value = data
        const payload = {
          searchQuery: data,
          page: 1
        }
        searchSchool(payload)
      }
    }

    const searchValue = ref('')

    const selectedSchool = ref({})

    function onItemClick(item) {
      searchValue.value = item.name
      selectedSchool.value = item
      clearSearch()
    }

    function clearSearch() {
      // Reset the stored searched schools.
      store.commit(RESET_SEARCHED_SCHOOLS)
    }

    function searchSchool(payload) {
      return store.dispatch(SEARCH_SCHOOLS, payload)
    }

    function deleteTeacherButtonPressed() {
      deleteTeacherWarning()
    }

    function validateData(formData) {
      formData.schoolId = selectedSchool.value.id ?? teacherDetails.value.schoolID
      formData.teacherId = teacherId
      // TODO: Validate data.
      // TODO: make API call ensuring data is changed.
      store.dispatch(HELPDESK_EDIT_TEACHER, formData).then(response => {
        if (response) {
          router.push({
            name: ROUTE_NAMES_HELPDESK.TEACHER_DETAILS,
            params: { teacherId: teacherId }
          })
        }
      })
    }

    function deleteTeacherWarning() {
      const payload = {
        modalOptions: {
          modalContent: BASIC_MODAL_CONTENT.DELETE_TEACHER(teacherDetails.value.name),
          cancelCallback: null,
          executeCallback: onTeacherDeletion
        },
        activeModal: MODAL_NAMES.DELETE_TEACHER
      }
      // After successful validation, the user should see a warning popup.
      store.commit(SET_BASIC_MODAL_DATA, payload)
    }

    /** Delete **/
    const deleteWarningModal = ref(null) // The ref of the Vue component so we can access it.



    function onTeacherDeletion() {
      // Check if the checkboxes have been all clicked. If they are not, an error is automatically displayed.
      const areAllCheckboxesChecked = deleteWarningModal.value.validateCheckboxes()
      if (areAllCheckboxesChecked) {
        deleteTeacher()
        store.commit(RESET_BASIC_MODAL_DATA)
      }
    }

    function deleteTeacher() {
      store.dispatch(DELETE_TEACHER, teacherId).then(() => {
        router.push({ name: ROUTE_NAMES_HELPDESK.TEACHERS_OVERVIEW })
        store.commit(SET_TOAST_DATA, { text: 'Leraar successvol verwijderd', src: require('../../../assets/icons/icn_successful.svg') })
      })
    }

    return {
      ROUTE_NAMES_HELPDESK,
      useStore,
      manageSearch,
      clearSearch,
      searchSchool,
      searchResults,
      onItemClick,
      deleteTeacherButtonPressed,
      teacherDetails,
      validateData,
      activeBasicModal,
      MODAL_NAMES,
      deleteWarningModal,
      searchValue,
      teacherId
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/base.variables';
@import '~@/assets/css/base.mixins';

.search-menu {
  margin-bottom: 1.75rem;
}

.delete-school {
  font-size: rem(16);
  padding: rem(11) rem(22);
}
</style>
